.countdown-container {
    display: flex;
    justify-content: space-around;
    font-size: 5rem;
    color: #333;
  }
  
  .countdown-item {
    text-align: center;
  }
  
  .countdown-circle {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px;
    font-size: 1.2rem;
    color: #fff;
  }
  
  .countdown-label {
    display: block;
    font-size: 1.2rem;
    color: #777;
  }
  /* Responsive styles */
@media (max-width: 768px) {
  .countdown-container {
    flex-direction: column;
    align-items: center;
  }
}
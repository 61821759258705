*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    

}

#logo2{
    width: 100px;
    height: 100px;
}
.headersmart{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 60px 10%;
    background-color: #052c33;
}
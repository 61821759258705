/* PhotoModal.css */
.photo-modal {
    position: relative;
  }
  
  .thumbnail {
    width: 800px; /* Adjust the size as needed */
    cursor: pointer;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    max-width: 80%;
    max-height: 100%;
    overflow: hidden;
  }
  
  .modal-photo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media (max-width: 768px) {
    .modal-content {
      max-width: 90%;
      max-height: 90%;
    }
  }
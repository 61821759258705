/* 7. About */
.about-img {
	position: relative;
	padding-left: 120px;
}
.about-img img.img-two {
	position: absolute;
	left: 60px;
	top: 40px;
}
.about-content {
	margin-left: 70px;
}
.section-title .sub-title {
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	font-weight: 700;
	position: relative;
	letter-spacing: 0.09em;
	display: inline-block;
	padding: 0 15px;
	margin-bottom: 25px;
}
.section-title .sub-title::after,
.section-title .sub-title::before {
	content: "";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 10px;
	height: 10px;
	background: #00C4F4;
	border-radius: 50%;
}
.section-title .sub-title::after {
	left: auto;
	right: 0;
}
.section-title .title {
	font-size: 42px;
	margin-bottom: 0;
	letter-spacing: -0.01em;
}
.section-title .title span {
	color: #00C4F4;
}
.about-content p {
	margin-bottom: 40px;
	width: 73%;
	color: #A4B4C3;
}
.partner-wrap ul {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.partner-wrap ul li {
	width: 20%;
	height: 123px;
	border: 1px solid rgba(255, 255, 255, 0.06);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: -1px;
	margin-top: -1px;
	cursor: pointer;
}
.partner-wrap ul li img {
	opacity: .5;
	transition: .3s linear;
}
.partner-wrap ul li:hover img {
	opacity: 1;
}

/* about-two */
.about-area-two {
	padding: 130px 0;
	position: relative;
}
.section-title.section-title-two .sub-title {
	color: #564DCA;
	letter-spacing: 0.02em;
	padding: 0;
	text-decoration: underline;
	margin-bottom: 18px;
}
.section-title.section-title-two .sub-title::before,
.section-title.section-title-two .sub-title::after {
	display: none;
}
.section-title.section-title-two .title {
	font-size: 45px;
	color: #030B15;
	line-height: 1.26;
}
.about-content-two p {
	color: #727885;
	margin-bottom: 20px;
	width: 80%;
}
.about-content-two .about-list {
	margin-bottom: 45px;
}
.about-content-two .about-list ul li {
	display: flex;
	align-items: baseline;
	color: #030B15;
	margin-bottom: 10px;
}
.about-content-two .about-list ul li:last-child {
	margin-bottom: 0;
}
.about-content-two .about-list ul li i {
	color: #564DCA;
	margin-right: 10px;
}
.about-shape-wrap img {
	position: absolute;
}
.about-shape-wrap img.shape-one {
	left: 9%;
	bottom: 90px;
	animation: leftToRight 5s infinite linear;
}
.about-shape-wrap img.shape-two {
	right: 7%;
	bottom: 22%;
}
.rotateme {
  -webkit-animation-name: teamRotate;
  animation-name: teamRotate;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
     transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes teamRotate {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* 8. Choose */
.choose-area .row {
	margin: 0 -10px;
}
.choose-area .row [class*="col-"] {
	padding: 0 10px;
}
.choose-item {
	background: #030B15;
	border: 1px solid #121A23;
	border-radius: 15px;
	padding: 60px 40px;
	transition: .3s ease-in-out;
}
.choose-item:hover {
	border-color: transparent;
}
.choose-icon {
	width: 80px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.07);
	border: 4px solid rgba(255, 255, 255, 0.06);
	border-radius: 50%;
	margin-bottom: 30px;
	transition: .3s ease-in-out;
}
.choose-item:hover .choose-icon {
	border-color: #00C4F4;
}
.choose-content .title {
	font-size: 22px;
	margin-bottom: 24px;
	font-weight: 500;
	text-transform: none;
	line-height: 1.27;
}
.choose-content p {
	margin-bottom: 0px;
	font-size: 15px;
	line-height: 22px;
	color: #A4B4C3;
}
.choose-area .sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.choose-area .slide-progress {
	display: block;
	width: 100%;
	height: 3px;
	border-radius: 0;
	background: rgba(255, 255, 255, 0.10);
	overflow: hidden;
	background-image: linear-gradient(to right, #00C4F4, #00C4F4);
	background-repeat: no-repeat;
	background-size: 0 100%;
	transition: background-size .4s ease-in-out;
	margin-top: 50px;
}




/* Page.css */
.page-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.photo-section {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.code-section {
  margin-top: 20px;
  background-color: #f4f4f4;
  padding: 15px;
  border-radius: 5px;
}

/* Add more styles as needed */
.chat-bubble {
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
  }
  
  .user {
    background-color: #f2f2f2;
    text-align: right;
  }
  
  .assistant {
    background-color: #e6f7ff;
  }
  
  .code-snippet {
    position: relative;
  }
  
  button {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }